<template>
  <div class="file-class">
    <el-image :src="fileUrl" fit="cover" v-if="fileUrl != null" style="margin-right:5px" :preview-src-list="[fileUrl]"
      :preview-teleported="true" />
  </div>
</template>
<script setup name="ShowFiles">
import { getFileUrl } from "@/api/wms/file"
import { computed, watch, ref, onMounted } from "vue"
const { proxy } = getCurrentInstance()
const emits = defineEmits(['getFile'])
const props = defineProps({
  files: {
    type: [String],
    default: ''
  },
  notes: {
    type: [String],
    default: ''
  }
})
const fileUrl = ref(null)

watch(
  () => props.files,
  async (val) => {
    if (val) {
      console.log('props.files', props.files)
      let data = await getFileUrl(val)
      fileUrl.value = data.data
      console.log('fileUrl.value', fileUrl.value)
    }
  },
  { deep: true, immediate: true }
)
</script>
<style lang="less">
.show-files-new-box {
  min-width: 85px !important;
}
</style>
<style scoped lang="scss">
.file-box {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  &-item {
    .file {
      width: 60px;
      height: 60px;
      padding: 5px;
      border: 1px solid rgba(0, 0, 0, .08);
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}

.file-class {
  width: 100%;
  height: 100%;
  display: flex; // 确保 display 属性设置为 flex
  align-items: center; // 确保内容垂直居中
  box-sizing: border-box;
  flex-wrap: nowrap;
}
</style>